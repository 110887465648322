<template>
<div class="card rounded-bottom-right">
                    <div v-if="!preLead" class="card-body">
                        <div class="d-flex align-items-center mb-4">
                            <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Get a Life Quote</h3>
                        </div>
                        <div class="row justify-content-center list-group-flush mb-4">
                            <div class="col-12">
                                <div id="form-contact">
                                    
                                    <div class="form-group">
                                        
                                        <select @change="$parent.amountChange" class="form-control custom-select-lg">
                                            <option selected>Amount</option>
                                            <option value="100000">$100,000</option>
                                            <option value="95000">$95,000</option>
                                            <option value="90000">$90,000</option>
                                            <option value="85000">$85,000</option>
                                            <option value="80000">$80,000</option>
                                            <option value="75000">$75,000</option>
                                            <option value="70000">$70,000</option>
                                            <option value="65000">$65,000</option>
                                            <option value="60000">$60,000</option>
                                            <option value="55000">$55,000</option>
                                            <option value="50000">$50,000</option>
                                            <option value="45000">$45,000</option>
                                            <option value="40000">$40,000</option>
                                            <option value="35000">$35,000</option>
                                            <option value="30000">$30,000</option>
                                            <option value="25000">$25,000</option>
                                            <option value="20000">$20,000</option>
                                            <option value="15000">$15,000</option>
                                            <option value="10000">$10,000</option>
                                            <option value="5000">$5,000</option>
                                          </select>
                                    </div>
                                    <div class="form-group">
                                        
                                        <select @change="$parent.stateChange" class="form-control custom-select-lg">
                                            <option selected>State</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>     
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NM">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>

                                        </select>
                                    </div>
                                    <div class="form-group">
                                   
                                        <select @change="$parent.ssChange" class="form-control custom-select-lg">
                                            <option selected>Smoking Status</option>
                                            <option value="ns">No</option>
                                            <option value="ss">Yes</option>                                          
                                          </select>
                                    </div>
                                    <div class="form-group">
                                        <select @change="$parent.genderChange" class="form-control custom-select-lg">
                                            <option selected>Gender</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>
                                          </select>
                                    </div>
                                    
                                    <div class="form-group">
                                        
                                        <div class="row">
                                            <div class="col-4" style="padding-right:2px;">
                                                <select @change="$parent.changeMonth" class="form-control custom-select-lg">
                                                    <option selected>Month</option>
                                                    <option value="01">01 - January</option>
                                                    <option value="02">02 - February</option>
                                                    <option value="03">03 - March</option>
                                                    <option value="04">04 - April</option>
                                                    <option value="05">05 - May</option>
                                                    <option value="06">06 - June</option>
                                                    <option value="07">07 - July</option>
                                                    <option value="08">08 - August</option>
                                                    <option value="09">09 - September</option>
                                                    <option value="10">10 - October</option>
                                                    <option value="11">11 - November</option>
                                                    <option value="12">12 - December</option>
                                                </select> 
                                            </div>
                                            <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                               
                                                <select @change="$parent.changeDay" class="form-control custom-select-lg">
                                                    <option selected>Day</option>
                                                    <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                                   
                                                </select> 
                                            </div>
                                            <div class="col-4" style="padding-left:2px;">
                                               <select @change="$parent.changeYear" class="form-control custom-select-lg" >
                                                    <option selected>Year</option>
                                                    <option v-for="i in 100" :key="i" :value="$parent.todayYear-i">{{ $parent.todayYear-i }}</option>
                                                </select> 
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center">
                                        <button type="reset" class="btn-reset d-none">

                                        </button> 
                                        <button v-if="!loading" type="submit" @click="submitQuote2()" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                            <span>Get your Estimate</span>
                                            
                                        </button>
                                        <button v-else class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                            <span>
                                                <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                        </button>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                    <div v-if="preLead" class="card-body">
                        <div class="d-flex align-items-center mb-4">
                            <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Get a Free Life Quote</h3>
                        </div>
                        <div class="row justify-content-center list-group-flush mb-4">
                            <div class="col-12">
                                <div class="card-header py-4 border-0 delimiter-bottom text-center" style="margin-top:-10px;">
                                    <div class="h4 text-center mb-0">
                                        <span class="price font-weight-bolder">Recieve your instant quote 

                                        </span>
                                        
                                    </div>
                                   
                                    
                                </div>
                                <div class="card-body">
                                
                                        
                                    
                                    
                                    <ul class="list-unstyled text-sm mt-2 mb-2">
                                        
                                        <div class="form-group">
                                            <label class="form-control-label">Name</label>
                                            <div class="input-group">
                                                
                                                <input type="text" v-model="$store.state.directForm.name" class="form-control" id="input-email" placeholder="James Wallace">
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-control-label">Email</label>
                                            <div class="input-group">
                                                
                                                <input type="email" v-model="$store.state.directForm.email" @input="validateEmail" class="form-control" id="input-email" placeholder="name@example.com">
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="form-group">
                                            <label class="form-control-label">Phone (optional)</label>
                                            <div class="input-group">
                                                
                                                <input type="text" v-model="$store.state.directForm.phone" @input="acceptNumber" class="form-control" id="input-email" placeholder="(866) 458-9824">
                                            </div>
                                        </div>
                                        
                                        
                                    </ul>
                                    
                                </div>
                                <div id="form-contact">
                                    
                                    
                                    
                                    
                                    
                                    
                                    <div class="text-center">
                                        
                                        <button v-if="!preLoading" @click="preQuote()" type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                            <span>View Quote</span>
                                            
                                        </button>
                                        <button v-else class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                            <span>
                                                <div class="spinner-border" role="status">
                                                <span class="sr-only">Loading...</span>
                                                </div>
                                            </span>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name: 'home-quote',
    data() {
        return {
            loading: false,
            preLoading: false,
            preLead: false,
            form: [],
            emailValid: '',
            directForm: {
                name: '',
                email: '',
                phone: '',
            }
        }
    },
    methods: {
        validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$store.state.directForm.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
        acceptNumber() {
            var x = this.$store.state.directForm.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.$store.state.directForm.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
        pushDirectQuote() {
            this.$router.push({name: 'Quote'});
        },
        preQuote() {
            this.form = this.$parent.form;
            this.$store.state.directForm.policy = this.form;
            if(this.$store.state.directForm.name != '' && this.$store.state.directForm.email != '' && this.emailValid == true) {
               this.preLoading = true;
                axios.post('/api/submitquoteproxy2', this.$store.state.directForm).then(resp => {
                    console.log(resp.data);
                    this.pushDirectQuote();
                    this.preLoading = false;
                }).error(err => {
                    this.preLoading = false;
                });
            } else if(this.$store.state.directForm.name == '') {
                this.$toasted.error('Name');
            } else if(this.$store.state.directForm.email == '') {
                this.$toasted.error('Email');
            } else if(this.emailValid != true) {
                this.$$toasted.error('Email');
            }
        },
        submitQuote938723() {
            this.form = this.$parent.form;
            if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
                this.form.dob = this.$parent.selectedMonth + '/' + this.$parent.selectedDay + '/' + this.$parent.selectedYear;
                console.log(this.$parent.form);
                
                if(this.form.amount < 45000) {
                    this.loading = true;
                    this.form.quoteType = 'fixed';
                    this.$store.state.quoteType = 'fixed';
                    console.log('less than 45k')
                    axios.post('/api/fetchquoteall', this.form).then(resp => {
                        console.log(resp.data);
                        /* this.$store.state.allQuotes = resp.data.qval; */
                        
                        this.$store.state.allQuotes = [];
                        this.$store.state.policy = this.form;
                        this.$store.state.policy.age = resp.data.diff;

                        resp.data.qval.forEach(this.iterateProducts);
                        this.preLead = true;
                        this.loading = false;
                    }).then(resp2 => {
                        /* this.pushDirectQuote() */
                    });
                }
                if(this.form.amount >= 45000) {
                    this.loading = true;
                    this.form.quoteType = 'term';
                    this.$store.state.quoteType = 'term';
                    console.log('more or equal to 45k');
                    this.$store.state.policy = this.form;
                    this.$store.state.policy.age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
                    /* this.pushDirectQuote() */
                    this.preLead = true;
                    this.loading = false;
                    
                }

            } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
            } else if(this.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.form.ss == '') {
                this.$toasted.error('Select Smoking Status');
            } else if(this.form.gender == '') {
                this.$toasted.error('Select Gender');
            } else if(this.selectedDay == '') {
                this.$toasted.error('Select Day');
            } else if(this.selectedMonth == '') {
                this.$toasted.error('Select Month');
            } else if(this.selectedYear == '') {
                this.$toasted.error('Select Year');
            }
        },
        submitQuote2() {
            
            if(this.$store.getters.loggedIn) {
                if(this.$parent.form.amount !== '' && this.$parent.form.state !== '' && this.$parent.form.ss !== '' && this.$parent.form.gender !== '' && this.$parent.selectedMonth !== '' && this.$parent.selectedDay !== '' && this.$parent.selectedYear !== '') {
                    if(this.$parent.form.amount >= 50000) {
                        
                        this.$parent.showQuoter = 'term';
                        this.submitTermQuote();
                    }
                    if(this.$parent.form.amount < 50000) {
                        this.$parent.showQuoter = 'fixed';
                        this.submitQuote();
                    }
                }
            }
            if(!this.$store.getters.loggedIn) {
                
                this.$router.push({name: 'RegisterAll2'});
            }
            
        },
        submitTermQuote() {
            this.$store.state.shelf = [];
            this.$parent.form.dob = this.$parent.selectedMonth + '/' + this.$parent.selectedDay + '/' + this.$parent.selectedYear;
            if(this.$parent.form.amount != '' && this.$parent.form.state != '' && this.$parent.form.ss != '' && this.$parent.form.gender != '' && this.$parent.selectedMonth != '' && this.$parent.selectedDay != '' && this.$parent.selectedYear != '') {
                this.$store.state.quoteType = this.$parent.showQuoter;
                this.$store.state.policy = this.$parent.form;
                this.$store.state.policy.age = moment(this.$store.state.policy.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
                this.loading = true;
                this.pushDirectQuote()
            } else if(this.$parent.form.amount == '') {
                this.$toasted.error('Select Amount');
            } else if(this.$parent.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.$parent.form.ss == '') {
                this.$toasted.error('Smoking Status');
            } else if(this.$parent.form.gender == '') {
                this.$toasted.error('Gender');
            } else {
                console.log(this.$parent.form)
            }
        },
        submitQuote() {
            this.$store.state.shelf = [];
            this.$parent.form.dob = this.$parent.selectedMonth + '/' + this.$parent.selectedDay + '/' + this.$parent.selectedYear;
            if(this.$parent.form.amount != '' && this.$parent.form.state != '' && this.$parent.form.ss != '' && this.$parent.form.gender != '' && this.$parent.selectedMonth != '' && this.$parent.form.selectedDay != '' && this.$parent.form.selectedYear != '') {
                this.loading = true;
                axios.post('/api/fetchquoteall', this.$parent.form).then(resp => {
                    console.log(resp.data);
                    /* this.$store.state.allQuotes = resp.data.qval; */
                    
                    this.$store.state.allQuotes = [];
                    this.$store.state.quoteType = this.$parent.showQuoter;
                    this.$store.state.policy = this.$parent.form;
                    this.$store.state.policy.age = resp.data.diff;
                
                    resp.data.qval.forEach(this.iterateProducts);
                    this.loading = false;
                }).then(resp2 => {
                    this.pushDirectQuote()
                });
            } else if(this.$parent.form.amount == '') {
                this.$toasted.error('Select Amount');
            } else if(this.$parent.form.state == '') {
                this.$toasted.error('Select State');
            } else if(this.$parent.form.ss == '') {
                this.$toasted.error('Smoking Status');
            } else if(this.$parent.form.gender == '') {
                this.$toasted.error('Gender');
            } else {
                console.log(this.$parent.form)
            }
        },
        iterateProducts(i) {
            console.log('Inside iterate products');
            console.log(i)

            let rate = '';
            let quote = '';
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ns') {
                
                rate = i.m_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Male' && this.$store.state.policy.ss == 'ss') {
                rate = i.m_ss_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ns') {
                rate = i.f_ns_rate;
            }
            if(this.$store.state.policy.gender == 'Female' && this.$store.state.policy.ss == 'ss') {
                rate = i.f_ss_rate;
            }

            /* Set logic to set min and max values depending on product  */
            let amount = Number(this.$store.state.policy.amount).toFixed(2);

            if(amount < Number(i.min_coverage)) {
                amount = Number(i.min_coverage).toFixed(2);
            }
            if(amount > Number(i.max_coverage)) {
                amount = Number(i.max_coverage).toFixed(2);
            }
            amount = Number(amount) / 1000;
            

            
            
            console.log('Amount: ' + amount);
            quote = (((rate * amount) + Number(i.premium)) * i.mode_eft);
            i.amount = amount;
            i.quote = quote;
            if(Number(amount * 1000) == Number(this.$store.state.policy.amount)){
                this.$store.state.allQuotes.push(i);
            }
        },
        PushDirectQuote() {
            
            this.$router.push({name: 'Quote'});
        },
    }

}
</script>
<template>
<header v-if="$route.name !== 'admin'" class=header-transparent id=header-main>
            <nav class="navbar navbar-main navbar-expand-lg navbar-dark shadow" id=navbar-main
                
            >
                <div class=container>
                
                <router-link class=navbar-brand :to="{name: 'Home'}">
                    <h2 class="display-6 text-white my-4" style="font-weight:100">Visionary Life</h2>
                </router-link>
                <button class=navbar-toggler type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                    <span class=navbar-toggler-icon></span>
                </button>
                <div class="collapse navbar-collapse navbar-collapse-overlay" id=navbar-main-collapse>
                    <div class=position-relative>
                        <button class=navbar-toggler type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                            <i data-feather=x></i>
                        </button>
                    </div>
                    <ul class="navbar-nav ml-lg-auto">
                        <li class="nav-item nav-item-spaced d-none d-lg-block">
                            <router-link class=nav-link :to="{name: 'Home'}">Home</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-none d-lg-block">
                            <router-link class=nav-link :to="{name: 'Overview'}">Overview</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-none d-lg-block">
                            <router-link class=nav-link :to="{name:'Benefits'}">Benefits</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-none d-lg-block">
                            <router-link class=nav-link :to="{name: 'HowToApply'}">How to Apply</router-link>
                        </li>
                        
                        
                        <li class="nav-item nav-item-spaced d-none d-lg-block">
                            <router-link class=nav-link :to="{name: 'Contact'}">Contact Us</router-link>
                        </li>
                        
                        
                       
                </ul>
                <ul class="navbar-nav align-items-lg-center d-none d-lg-flex ml-lg-auto">
                    <li class=nav-item>
                        <router-link class=nav-link :to="{name: 'Login'}">
                            Sign in
                        </router-link>
                    </li>
                    <li class=nav-item>
                        <router-link class=nav-link :to="{name: 'Register'}">
                            Register
                        </router-link>
                    </li>
                    
                </ul>
                
            </div>
        </div>
    </nav>
   </header>
</template>
<script>
export default {
    name: 'header-dark',
}
</script>
<template>
<div>
  
  <section class="slice slice-lg bg-primary" style="background-image:url('/img/family.jfif');background-size:cover;">
    <span class="mask bg-gradient-dark opacity-4"></span>
    <div class="container d-flex align-items-center pt-lg-6">
        <div class="col px-0"><div class="row row-grid align-items-center">
            <div class="col-lg-6 text-center text-lg-left">
                <h1 class="display-4 text-white my-4">Visionary Inc.</h1>
                <p class="lead text-white opacity-8">Envision what your financial future looks like and let us help you plan today for a better tomorrow

 
                </p>
                <div class="mt-5">
                    <button @click="$router.push({name: 'HowToApply'})" class="btn btn-white btn-lg btn-icon">
                        <span class="btn-inner--text">Get started</span><span class="btn-inner--icon"> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </span>
                    </button>
                </div>
                <div class="d-flex align-items-center justify-content-center justify-content-lg-left mt-5">
                    
                </div>
            </div>
            <div class="col-lg-6 col-xl-5 col-sm-8 ml-auto mr-auto mr-lg-0">
                <home-quote></home-quote>
            </div>
        </div>
    </div>
</div>
<div class="shape-container shape-position-bottom shape-line">
    <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100" xml:space="preserve" class=""><polygon points="2560 0 2560 100 0 100"></polygon></svg>
</div>
  </section>
  <section class="slice bg-section-secondary">
      <div class="container position-relative zindex-100">
          <div class="row mb-5 justify-content-center text-center">
              <div class="col-lg-8 col-md-10">
                  <h3 class="mt-4">What do we have to offer?</h3>
                  <div class="mt-3">
                      <p class="lead lh-180">Life Insurance Plans that include term, permanent coverage, access to benefits if diagnosed with a serious illness. Options abilable that don't require an exam or lab work</p>
                   </div>
                </div>
            </div>
            <div class="row row-grid justify-content-around align-items-center">
                <div class="col-lg-5">
                    <h5 class="h2">Have you thought about saving for college, purchasing a home, retirement?</h5>
                    <p class="lead my-4">
                        Did you know that the younger you are the less expensive it is to purchase life insurance? Time is not on our side as we age unfortunately.  Our chances of being diagnosed with a medical condition grows higher every day we get older. This causes the cost of life insurance to go up.  Save money by starting young!
                    </p>
                    <ul class="list-unstyled mb-0">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3"><i class="fas fa-file-invoice"></i>
                                </div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">Life insurance policies enable you to earn and build up cash value</span>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3"><i class="fas fa-store-alt"></i></div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">You can later borrow the funds from your policy to pay off bills, or even put a down payment on that townhouse</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6">
                    <img alt="Image placeholder" src="https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" class="img-fluid">
                </div>

                
            </div>

            <!-- // Second Main Section -->
            <div class="row row-grid justify-content-around align-items-center">
                <div class="col-lg-5 order-2">
                    <h5 class="h2">Who will take care of your final expenses?</h5>
                    <p class="lead my-4">
                        What is the true retirement age? It actually depends on each person's individual needs, wants, and ability to work.  One thing is certain the bills don't pay themselves and we would all still like to have the opportunity to take a vacation or host a family gathering, perhaps help out a family member or friend when they are in need.  It's important to know what our total monthly income will be once we exit the work force.  What will your, Social Security,  Income from a 401K plan, Pension, and Investments.  We also need to think about our Final Expenses, such has final bills that need to be paid when we pass away, and how our funeral expenses will be covered. This isn't something we like think or talk about, but it's necessary, and needs to be part of our retirement budget! Implementing your retirement plan can be a headache, we are here to help!
                    </p>
                    <ul class="list-unstyled mb-0">
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3"><i class="fas fa-file-invoice"></i>
                                </div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">We offer policies that will help pay for these medical expenses, and other bills you may fall behind on when these situations occur</span>
                                </div>
                            </div>
                        </li>
                        <li class="py-2">
                            <div class="d-flex align-items-center">
                                <div>
                                    <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3"><i class="fas fa-store-alt"></i></div>
                                </div>
                                <div>
                                    <span class="h6 mb-0">This plan would ensure that family values and important financial matters to the family are addressed and managed</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-6 order-1">
                    <img alt="Image placeholder" src="https://images.unsplash.com/photo-1533444273691-ebf51af8fd9c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80" class="img-fluid">
                </div>

                
            </div>
    </div>
</section>
  
    <section class="slice slice-lg">
        <div class="container">
            <h1 class="d-inline-block mb-5 text-highlight-warning text-center">What's important?</h1>
            <div class="row justify-content-between mt-7 mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Supporting Our Family</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        Families come in all shapes and sizes. Married, divorces, no kids to be ten kids. We love our family and if you have kids, you do your best to provide for them! Such as shelter, food, education, clothes. making birthdays special, coughing up dough for a field trip. Oh yeah... teaching them how to drive, disciplining them when they get reckless with the car or are out too late with their friends! Don't forget, the list goes on!
                    </p>
                    <p>
                        Working full time as one parent or two parent household, this can be tough to do. We can make it easier to save towards their college education or for other future expenses that may come along the way, such as a wedding, or perhaps a bigger and better house is needed because there is another bun in the oven. The key is to start a plan now when there is time to build up the cach that will be needed in the future.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Planning For Retirement</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        It's NEVER too late to start planning for our retirement. The younger the better! It takes time to build a solid retirement plan, and you want to have the flexibility of picking and choosing which plans are best for you. Everyone is unique, so current income, debt obligations and risk tolereance is going to need to be reviewed and a customized plan put in place. Sometimes it's hard to envision how we want out retirement to play outwhen it is 20+ years away, but we know it's time to think about what you will want to be able to do when retires and how much monthly income it is going to take to achieve this goal.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">
                        Accident & Illness</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        We don't have the ability to see what our future holds, and unfortunately Accidents and Illnesses blind side up from time to time. Working 40+ hours per week comes to a screeching halt when these things happen. Putting us out of work for weeks are months. The bits don't stop comming, and most of us don't have ample "PTO" at work to pay for this, we may have a disability policy that will pay out a % of our income, but if we are living paycheck to paycheck, we know that won't work. We have insurance plans available that will allow you to access funds when severe accidents and illness occur, so that the bills can continue to be paid while healing.
                    </p>
                    
                </div>
            </div>
            <div class="row justify-content-between mb-5">
                <div class="col-lg-3 text-lg-right">
                    <h4 class="mb-4">Medical Expenses</h4>
                </div>
                <div class="col-lg-8">
                    <p>
                        Most of us have had to pay medical bills such as premium payments, co-pays, prescriptions, and deductables. These expenses can add up! Outside of regular routine healthcare expenses, we sometimes have a medical emergency that can significantly throw off out budget. It can be difficult to save emergency money for these unforseen events. We offer policies that will help pay for these medical expenses, and other bills you may fall behind on when these situtations occur
                    </p>
                    
                </div>
            </div>
            
           
       
    </div>
    </section>
  
  <!-- <section class="slice slice-lg">
    <div class="container" style="margin-top: -105px;">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                
                
                <h1 class="d-inline-block mt-7 mb-5 text-highlight-warning text-center">What's important?</h1>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">1</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">Supporting Our Family</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">
                                   Families come in all shapes and sizes. Married, divorces, no kids to be ten kids. We love our family and if you have kids, you do your best to provide for them! Such as shelter, food, education, clothes. making birthdays special, coughing up dough for a field trip. Oh yeah... teaching them how to drive, disciplining them when they get reckless with the car or are out too late with their friends! Don't forget, the list goes on!   
                                </p>
                                <p>
                                    Working full time as one parent or two parent household, this can be tough to do. We can make it easier to save towards their college education or for other future expenses that may come along the way, such as a wedding, or perhaps a bigger and better house is needed because there is another bun in the oven. The key is to start a plan now when there is time to build up the cach that will be needed in the future.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">2</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">Planning For Retirement</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">
                                    It's NEVER too late to start planning for our retirement. The younger the better! It takes time to build a solid retirement plan, and you want to have the flexibility of picking and choosing which plans are best for you. Everyone is unique, so current income, debt obligations and risk tolereance is going to need to be reviewed and a customized plan put in place. Sometimes it's hard to envision how we want out retirement to play outwhen it is 20+ years away, but we know it's time to think about what you will want to be able to do when retires and how much monthly income it is going to take to achieve this goal.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">3</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">Accident & Illness</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">
                                    We don't have the ability to see what our future holds, and unfortunately Accidents and Illnesses blind side up from time to time. Working 40+ hours per week comes to a screeching halt when these things happen. Putting us out of work for weeks are months. The bits don't stop comming, and most of us don't have ample "PTO" at work to pay for this, we may have a disability policy that will pay out a % of our income, but if we are living paycheck to paycheck, we know that won't work. We have insurance plans available that will allow you to access funds when severe accidents and illness occur, so that the bills can continue to be paid while healing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">4</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">Medical Expenses</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">
                                    Most of us have had to pay medical bills such as premium payments, co-pays, prescriptions, and deductables. These expenses can add up! Outside of regular routine healthcare expenses, we sometimes have a medical emergency that can significantly throw off out budget. It can be difficult to save emergency money for these unforseen events. We offer policies that will help pay for these medical expenses, and other bills you may fall behind on when these situtations occur
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section> -->
  <section class="slice slice-lg">
    <div class=container>
        <div class="row mb-5 justify-content-center text-center">
            <div class="col-lg-8 col-md-10">
                <h2 class=mt-4>Need personal assistance?</h2>
                <div class=mt-2>
                    <p class="lead lh-180">If you still didn't find an answer you can always contact us</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-5" ><div class=card>
                <div class="card-body p-5 text-center">
                    <div class=pb-4>
                        <img alt="Image placeholder" src=https://preview.webpixels.io/quick-website-ui-kit/assets/img/svg/icons/Call_Out.svg class="svg-inject img-fluid" style=height:40px>
                    </div>
                    <h5>+1 (800) 256-5307</h5>
                    <p class="mt-2 mb-0">Our support team is here for you.</p>
                </div>
            </div>
        </div>
        <div class=col-md-5>
            <div class=card>
                <div class="card-body p-5 text-center">
                    <div class=pb-4>
                        <img alt="Image placeholder" src=https://preview.webpixels.io/quick-website-ui-kit/assets/img/svg/icons/Email.svg class="svg-inject img-fluid" style=height:40px>
                    </div>
                    <h5 style="font-size:1.15rem">
                        <router-link :to="{name: 'Contact'}">Questions@visionaryinsurance.life
                        </router-link></h5>
                    <p class="mt-2 mb-0">You'll get a reply in 4 to 12 hours.</p>
                </div>
            </div>
        </div>
    </div>
</div>
  </section>
</div>
</template>

<script>
import flatpickr from "flatpickr";
import axios from 'axios';
import headerDark from './comps/HeaderDark.vue';
import homeQuote from './comps/HomeQuote.vue';
export default {
  name: 'Home',
  components: {
    headerDark, homeQuote,
  },
  data() {
      return {
          loading: false,
          todayYear: new Date().getFullYear() - 18,
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
          showQuoter: '',
      }
  },
  mounted() {
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      submitQuote() {
          console.log('submitting quote');
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.$router.push({name: 'Quote'})
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  },
}
</script>
<style scoped>
  .form-control2 {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e8f0;
    border-radius: .375rem;
    box-shadow: inset 0 1px 1px rgb(31 45 61 / 8%);
    transition: all .2s ease;
  }
  #datepicker::-webkit-input-placeholder {
    padding-left:12px;
  }
  #datepicker::-ms-input-placeholder {
    padding-left: 12px;
  }
  #datepicker::placeholder {
    padding-left:12px;
  }

  $datepicker::value {
    color:red;
  }

</style>

import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('access_token') || null,
    token2: localStorage.getItem('access_token2') || null,
    tokenLocal: localStorage.getItem('access_token_local') || null,
    allQuotes: [],
    allTestQuotes: [],
    userTabSelected: '',
    policy: {
      age: '',
      amount: '',
      dob: '',
      gender: '',
      quote: '',
      ss: '',
      startdate: '',
      state: '',
      term: '', 
    },
    policySpouse: [],
    pAll: [],
    q: {
        policy_age: '',
        policy_amount: '',
        policy_dob: '',
        policy_gender: '',
        policy_quote: '',
        policy_ss: '',
        policy_startdate: '',
        policy_state: '',
        policy_term: '',
        product_name: '',

        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        email: '',
        phone: '',
        phone_confirm: '',
        address: '',
        address_no: '',
        city: '',
        state: '',
        zip: '',

        license_number: '',
        license_state: '',
        height_ft: '',
        height_in: '',
        weight: '',
        weight_ten: '',
        weight_loss: '',
        weight_reason: '',
        tobacco: '',
        med_one: '',
        med_two: '',
        med_three: '',
        med_four: '',
        med_five: '',


        med_six: '',
        med_seven: '',
        med_eight: '',
        med_nine: '',
        med_ten: '',
        med_eleven: '',
        med_twelve: '',
        med_thirteen: '',
        med_fourteen: '',

        med_fifteen: '',
        med_sixteen: '',
        med_seventeen: '',
        med_eighteen: '',



        additional_one: '',
        
        
        additional_two: '',
        additional_three: '',
        owner_one: '',
        owner_two: '',
        owner_three: '',
        owner_address: '',
        owner_addressno: '',
        owner_city: '',
        owner_state: '',
        owner_zip: '',
        owner_phone: '',
        secondaddres_one: '',
        secondaddress_two: '',
        secondaddress_address: '',
        secondaddress_addressno: '',
        secondaddress_city: '',



        secondaddress_state: '',


        secondaddress_zip: '',
        secondaddress_phone: '',
        ben_one: '',
        ben_two: '',
        ben_three: '',
        ben_four: '',
        ben_five: '',
        ben_six: '',
        addben_one: '',
        addben_two: '',


        addben_three: '',
        addben_four: '',
        addben_five: '',
        addben_six: '',

        contben_one: '',
        contben_two: '',
        contben_three: '',
        contben_four: '',
        contben_five: '',
        contben_six: '',

        exist_one: '',
        exist_two: '',
        exist_three: '',
        exist_four: '',
        exist_five: '',

        exist_six: '',
        thirdparty: '',

        payment_one: '',
        payment_two: '',

        payment_three: '',
        payment_four: '',
        payment_five: '',
        payment_six: '',
        payment_seven: '',
        payment_eight: '',

        payment_nine: '',
        payment_ten: '',
        payment_eleven: '',
        payment_expday: '',
        payment_expyear: '',
        payment_twelve: '',
        comments: '',

        timezone: '',
        schedule_hourid: '',
    },
    p: {
      policy_age: '',
      policy_amount: '',
      policy_dob: '',
      policy_gender: '',
      policy_quote: '',
      policy_ss: '',
      policy_startdate: '',
      policy_state: '',
      policy_term: '',
      product_name: '',

      first_name: '',
      last_name: '',
      dob: '',
      gender: '',
      email: '',
      phone: '',
      phone_confirm: '',
      address: '',
      address_no: '',
      city: '',
      state: '',
      zip: '',
      /* Start 2 */
      q1: '',
      
      /* Start 3 */
      q2: '',
      q3: '',
      q4: '',

      /* Start 4 */
      q5: '',
      q6: '',
      q7: '',
      q8: '',
      q9: '',

      /* Start 5 */
      q10: '',
      q11: '',

      /* Start 6 */
      q12: '',

      /* Start 7 */
      q13: '',
      q14: '',

      /* Start 8 */
      q15: '',
      q16: '',
      q17: '',
      q18: '',
      q19: '',
      q20: '',
      q21: '',
      q22: '',
      q23: '',
      q24: '',
      q25: '',
      q26: '',
      q27: '',
      q28: '',
      q29: '',
      q30: '',
      q31: '',
      q32: '',
      q33: '',
      q34: '',
      q35: '',
      q36: '',
      q37: '',
      q38: '',
      q39: '',
      q40: '',
      q41: '',
      q42: '',
      q43: '',
      q44: '',
      q45: '',
      q46: '',
      q47: '',
      q48: '',
      q49: '',
      q50: '',
      q51: '',
      q52: '',
      q53: '',
      q54: '',
      q55: '',
      q56: '',
      q57: '',
      q58: '',
      q59: '',
      q60: '',
      q61: '',
      q62: '',
      q63: '',
      q64: '',
      q65: '',
      q66: '',
      q67: '',
      q68: '',
      q69: '',
      q70: '',
      q71: '',
      q72: '',
      q73: '',
      q74: '',
      q75: '',
      q76: '',
      q77: '',
      q78: '',
      q79: '',
      q80: '',
      q81: '',
      q82: '',
      q83: '',
      q84: '',
      q85: '',
      q86: '',
      q87: '',
      q88: '',
      q89: '',

      q_text_1: '',
      q_text_2: '',
      q_text_3: '',
      q_text_4: '',
      q_text_5: '',
      q_text_6: '',
      q_text_7: '',
      q_text_8: '',
      q_text_9: '',
      q_text_10: '',
      
      

    },
    shelf: [],
    directForm: {
      name: '',
      email: '',
      phone: '',
    },
    rider1: '',
    rider2: '',
    agentRouteName: '',
    messageList: '',
    state: {
      AL: "Alabama",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      FL: "Florida",
      GA: "Georgia" ,    
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    },
    directForm: {
      isDiabetic: '',
      diabetic: '',
      type: '',
      insulin: '',
      a1c: '',
      years: '',
      height: '',
      heightFt: '',
      heightIn: '',
      weight: '',
      eye: '',
      eyeAdd: '',
      heart: '',
      heartAdd: '',
      blood: '',
      bloodAdd: '',
      kidney: '',
      kidneyAdd: '',
      sensory: '',
      sensoryAdd: '',
      other: '',
      otherAdd: '',
      covid1: '',
      covid2: '',
      name: '',
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      schedule_hourid: '',
      ticket_type: '',
    },
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
    isEnroller(state) {
      //return JSON.parse(state.token2).includes
      if((JSON.parse(state.token2).role).toLowerCase().includes('enroller')) {
        return (JSON.parse(state.token2).role).toLowerCase().includes('enroller');

      } else {
        return false;
      }
    },
    isAdmin(state) {
      //return JSON.parse(state.token2).includes
      return (JSON.parse(state.token2).role).toLowerCase().includes('admin');
    },
    isAgent(state) {
      //return JSON.parse(state.token2).includes
      return (JSON.parse(state.token2).role).toLowerCase().includes('agent');
    },
    isRecruiter(state) {
      //return JSON.parse(state.token2).includes
      return (JSON.parse(state.token2).role).toLowerCase().includes('recruiter');
    },
    returnRole(state) {
      return JSON.parse(state.token2).role;
    },
    returnUserName(state) {
      return JSON.parse(state.token2).name
    }
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      
    },
    destroyToken(state) {
      state.token = null;
      state.token2 = null;
    },
    setToken2(state, token) {
      state.token2 = token;
      
    },
    addPolicy(state, policy) {
      state.policy = policy;
    },
    addPolicySpouse(state, policy) {
      state.policySpouse = policy;
    },
    addToShelf(state, sitem) {
      let itemInShelfIndex = state.shelf.findIndex(item => item.id === sitem.id);
      if(itemInShelfIndex !== -1) {
        return;
      }
      state.shelf.push(sitem);
    },
    removeFromShelf(state, index) {
      let shelfIndex = state.shelf.findIndex(item => item.id === index);
      console.log(state.shelf[shelfIndex]);
      state.shelf.splice(shelfIndex, 1);
      
    },
    updateShelf(state, shelf) {
      state.shelf = shelf;
    },
    updateShelf2(state, shelf) {
      state.shelf = [];
    }
  },
  actions: {
    clearShelf({commit}) {
      commit('updateShelf', []);
    },
    clearShelf2({commit}) {
      commit('updateShelf2', []);
    },
    destroyToken(context) {
      axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
      if(context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios.post('/api/logout')
            .then(response => {
              localStorage.removeItem('access_token');
              localStorage.removeItem('access_token2');
              context.commit('destroyToken')
              resolve(response)
              
            })
            .catch(error => {
              localStorage.removeItem('access_token')
              localStorage.removeItem('access_token2')
              context.commit('destroyToken')
              console.log(error);
              this.$router.push('Home');
              reject(error)
            });
        });
      }
    },
    retrieveToken(context, credentials) {
      let form = [];
      return new Promise((resolve, reject) => {
        axios.get('/api/login').then(resp => {
          form.cs = resp.data.ci;
          form.id = resp.data.id;
          form.gt = resp.data.gp;
          axios.post('/oauth/token', {
              grant_type: form.gt,
              client_id: form.id,
              client_secret: form.cs,
              username: credentials.username,
              password: credentials.password,
          
          }).then(resp2 => {
            let authBear = resp2.data.access_token;
            localStorage.setItem('access_token', authBear);
            context.commit('setToken', authBear);
            axios.get('/api/user', {
              headers: {'Authorization': 'Bearer ' + authBear} 
            }).then(user => {
              resolve();
              let st = {id: user.data.id, email: user.data.email, name: user.data.name, role: user.data.role}
              localStorage.setItem('access_token2', JSON.stringify(st));
              localStorage.setItem('access_etoken', user.data.email);
              context.commit('setToken2',JSON.stringify(st));
            }).then(resp45 => {
              
            });
          }).catch(error => {
            
            reject(error)
          });
        });
      })
    },
  },
  modules: {
  }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/quote',
    name: 'Quote',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/QuoteOptions.vue')
  },
  {
    path: '/quoteoptions',
    name: 'QuoteOptions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/QuoteOptions.vue')
  },
  {
    path: '/overview',
    name: 'Overview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Overview.vue')
  },
  {
    path: '/lifeinsurance',
    name: 'Benefits',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Benefits.vue')
  },
  {
    path: '/visiondental',
    name: 'HowToApply',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VisionDental.vue')
  },
  {
    path: '/howtoapply',
    name: 'HowT333oApply',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/HowToApply.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/agents',
    name: 'Agents',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Agents2.vue')
  },
  {
    path: '/agent/signup',
    name: 'Agent.Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AgentSignup.vue')
  },
  {
    path: '/learnmore',
    name: 'Learnmore.Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LearnMore.vue')
  },
  {
    path: '/agent/signup/:slug',
    name: 'Agent.Signup.Slug',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AgentSignupSlug.vue')
  },
  {
    path: '/agent/success',
    name: 'Agent.Success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OboardingSuccess.vue')
  },
  {
    path: '/reqest-information/success',
    name: 'Requestinfo.Success',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RequestSuccess.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/ticket',
    name: 'Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Ticket.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/quote/login',
    name: 'Login2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login2.vue')
  },
  {
    path: '/register/become-an-agent',
    name: 'RegisterAll2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/RegisterAll.vue')
  },
  {
    path: '/register/carriers',
    name: 'RegisterAll',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContractingPage.vue')
  },
  {
    path: '/register/:slug',
    name: 'Register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/applied',
    name: 'Applied',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Applied.vue')
  },
  {
    path: '/logo',
    name: 'Logo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Logo.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminApp.vue'),
    meta: { requiresAuth: true  },
    children: [
        {
          path: '/',
          name: 'admin.tickets',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketList.vue'),
        },
        {
          path: '/a/quote',
          name: 'aquote',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/Quote.vue'),
        },
        {
          path: '/ticketview/:id',
          name: 'admin.ticket.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketView.vue'),
        },
        {
          path: '/ticketgeneralqs/:id',
          name: 'generalqs.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketForesters.vue'),
        },
        {
          path: '/questionsview/:id',
          name: 'admin.questions.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/QuestionsView.vue'),
        },
        {
          path: '/questionnaire-diabetics/:id',
          name: 'admin.ticket.direct',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketDirectView.vue'),
        },
        
        {
          path: '/scheduled',
          name: 'admin.scheduled',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/ScheduleList.vue'),
        },
        {
          path: '/agents',
          name: 'admin.agents',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/AgentList.vue'),
        },
        {
          path: '/applicants',
          name: 'admin.applicants',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/Applicants.vue'),
        },
        {
          path: '/applicants/:id',
          name: 'admin.applicants.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/ApplicantsView.vue'),
        },
        {
          path: '/recruiters',
          name: 'admin.recruitors',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/RecruitorsList.vue'),
        },
        {
          path: '/userroles',
          name: 'admin.userroles',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/UsersList.vue'),
        },
        {
          path: '/recruiter/:slug/agents',
          name: 'admin.recruitor.agents',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/RecruitorAgentList.vue'),
        },
        {
          path: '/enrollers',
          name: 'admin.enrollers',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/EnrollersList.vue'),
        },
        {
          path: '/messages',
          name: 'admin.messages',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/MessageList.vue'),
        },
        {
          path: '/quoter',
          name: 'admin.quoter',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/Quoter.vue'),
        },
        {
          path: '/users/:id',
          name: 'admin.users.view',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserView.vue'),
        },
        {
          path: '/tickets/agent/:slug',
          name: 'admin.tickets.list',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketAgentList.vue'),
        },
        {
          path: '/account',
          name: 'admin.account',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserAccount.vue'),
        },
        {
          path: '/qticket/:id',
          name: 'forester.questions',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/TicketForesters.vue'),
        },
        {
          path: '/prequalticket/:id',
          name: 'prequalticket.questions',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/PrequalTicket.vue'),
        },
        {
          path: '/prequalquestionnaire/:id',
          name: 'prequalquestionnaire',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/PrequalQuestionnaire.vue'),
        },
        {
          path: '/qcontactinfo',
          name: 'q.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ContactInformation.vue'),
        },
        {
          path: '/qgeneralinfo',
          name: 'q.generalinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/GeneralInformation.vue'),
        },
        {
          path: '/qweightloss',
          name: 'q.weightloss',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/WeightLoss.vue'),
        },
        {
          path: '/qtobacco',
          name: 'q.tobacco',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Tobacco.vue'),
        },
        {
          path: '/qmedical',
          name: 'q.medical',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/MedicalQuestions.vue'),
        },
        {
          path: '/qpast12',
          name: 'q.past12',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Past12.vue'),
        },
        {
          path: '/qpast36',
          name: 'q.past36',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Past36.vue'),
        },
        {
          path: '/qcovid19',
          name: 'q.covid19',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Covid19.vue'),
        },
        {
          path: '/qadditional',
          name: 'q.additional',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/AdditionalInformation.vue'),
        },
        {
          path: '/qownerinfo',
          name: 'q.ownerinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/OwnerInformation.vue'),
        },
        {
          path: '/qbeneficiary',
          name: 'q.beneficiary',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Beneficiary.vue'),
        },
        {
          path: '/qadditionalbeneficiary',
          name: 'q.additionalbeneficiary',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/AdditionalBeneficiary.vue'),
        },
        {
          path: '/qcontingentbeneficiary',
          name: 'q.contingentbeneficiary',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ContingentBeneficiary.vue'),
        },
        {
          path: '/qexistingcoverage',
          name: 'q.existingcoverage',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ExistingCoverage.vue'),
        },
        {
          path: '/qexistingdetails',
          name: 'q.existingdetails',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ExistingDetails.vue'),
        },
        {
          path: '/qexistingreplacement',
          name: 'q.existingreplacement',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ExistingReplacement.vue'),
        },
        {
          path: '/qthirdparty',
          name: 'q.thirdparty',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ThirdParty.vue'),
        },
        {
          path: '/qschedule',
          name: 'q.schedule',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ScheduleAppointment.vue'),
        },
        {
          path: '/qpaymentinformation',
          name: 'q.paymentinformation',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PaymentInformation.vue'),
        },
        {
          path: '/qelectronictransfer',
          name: 'q.electronictransfer',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/ElectronicTransfer.vue'),
        },
        {
          path: '/qauthorization',
          name: 'q.authorization',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Authorization.vue'),
        },
        {
          path: '/prequalcontactinfo',
          name: 'prequal.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/1ContactInformation.vue'),
        },
        {
          path: '/prequalcitizen',
          name: 'prequal.citizen',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/2Citizen.vue'),
        },
        {
          path: '/prequalgreen',
          name: 'prequal.green',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/3Green.vue'),
        },
        {
          path: '/prequalproductdetails',
          name: 'prequal.productdetails',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/4ProductDetails.vue'),
        },
        {
          path: '/prequalextended',
          name: 'prequal.extended',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/5.vue'),
        },
        {
          path: '/prequalpayment',
          name: 'prequal.payment',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/6Payment.vue'),
        },
        {
          path: '/prequallifestyle',
          name: 'prequal.lifestyle',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/7Lifestyle.vue'),
        },
        {
          path: '/prequalmedical1',
          name: 'prequal.medical1',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/8Medical1.vue'),
        },
        {
          path: '/prequalmedical2',
          name: 'prequal.medical2',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/9Medical2.vue'),
        },
        {
          path: '/prequalmedical3',
          name: 'prequal.medical3',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/10Medical3.vue'),
        },
        {
          path: '/prequalschedule',
          name: 'prequal.schedule',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/PreQual/11ScheduleAppointment.vue'),
        },
        {
          path: '/aigcontactinfo',
          name: 'aig.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/AIG-Guaranteed/ContactInformation.vue'),
        },
        {
          path: '/mutualomahacontactinfo',
          name: 'omaha.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Omaha-Living/ContactInformation.vue'),
        },
        {
          path: '/fidelitywholelifecontactinfo',
          name: 'fidelitywhole.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Fidelity-Whole/ContactInformation.vue'),
        },
        {
          path: '/fidelityfinalexpensecontactinfo',
          name: 'fidelityfinalexpense.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Fidelity-Expense/ContactInformation.vue'),
        },
        {
          path: '/fidelitytermcontactinfo',
          name: 'fidelityterm.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Fidelity-Term/ContactInformation.vue'),
        },
        {
          path: '/federalexpresscontactinfo',
          name: 'federalexpress.contactinfo',
          component: () => import(/* webpackChunkName: "about" */ '../views/admin/questions/Federal-Express/ContactInformation.vue'),
        },
    ]
  },
  {
    path: '/q/a',
    name: 'A',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/ADiabetic.vue')
  },
  {
    path: '/q/b',
    name: 'B',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/BType.vue')
  },
  {
    path: '/q/insulin',
    name: 'Insulin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/CInsulin.vue')
  },
  {
    path: '/q/a1c',
    name: 'A1c',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/A1c.vue')
  },
  {
    path: '/q/years',
    name: 'Years',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/DYears.vue')
  },
  {
    path: '/q/height',
    name: 'Height',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/EHeight.vue')
  },
  {
    path: '/q/weight',
    name: 'Weight',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/FWeight.vue')
  },
  {
    path: '/q/questions',
    name: 'Questions',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/GQuestions.vue')
  },
  {
    path: '/q/hquestions',
    name: 'Questions2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/HQuestions.vue')
  },
  {
    path: '/q/contact',
    name: 'Name',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/IName.vue')
  },
  {
    path: '/q/covid',
    name: 'Covid',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/CovidQuestions.vue')
  },
  {
    path: '/q/schedule',
    name: 'Schedule',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/form/JSchedule.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
